/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { EventsIndex as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query EventsIndexQuery {
    craft {
      entry(section: "eventsIndex") {
        title
        url
        slug
        ... on Craft_eventsIndex_eventsIndex_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const previewQuery = `query EventsIndexQuery {
  craft {
    entry(section: "eventsIndex") {
      title
      url
      slug
      ... on Craft_eventsIndex_eventsIndex_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    heroTitle,
    heroImage,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
  } = craft.entry;

  return {
    hero: {
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
